import React from "react";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class MyForm extends React.Component {
    constructor(props) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.state = {
            status: ""
        };
    }


    render() {
        toast.configure();
        const {status} = this.state;
        // const notify = () =>
        //   toast("Thank you for contacting upReach about the Social Mobility Network with the below request.upReach will aim to respond within two working days.",{className: "toast-bronze-background"});
        return (
            <form
                onSubmit={this.submitForm}
                action="https://formspree.io/f/xdopyykn"
                method="POST"
                style={{display: "grid"}}
            >
                <label className="form-label">Name<span className="astrix-color">*</span></label>
                <input type="text" name="name" className="input-label" placeholder="Full Name"/>
                <label className="form-label">Organisation/Role<span className="astrix-color">*</span></label>
                <input type="text" name="organisation" placeholder="Title" className="input-label"/>
                <label className="form-label">Email address<span className="astrix-color">*</span></label>
                <input type="email" name="email" placeholder="you@domain.com" className="input-label"/>
                <label className="form-label">Details of Request<span className="astrix-color">*</span></label>
                <textarea rows="3" name="details" placeholder="Max. 500 characters" className="input-label"/>
                {/* <ToastContainer bodyClassName="toast-box-body" autoClose={false} /> */}
                {status === "SUCCESS" ?
                    <div className="thanks-div">Thank you for contacting upReach about the Social Mobility Network with
                        the below request. upReach will aim to respond within two working days.</div> :
                    <button className="btn-color"><p className="register-btn">Submit</p></button>}
                {status === "ERROR" &&
                <p className="error-div">Something went wrong, please make sure you fill out all the fields above.</p>}
            </form>
        );
    }

    submitForm(ev) {
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                this.setState({status: "SUCCESS"});
            } else {
                this.setState({status: "ERROR"});
            }
        };
        xhr.send(data);
    }
}