import React, { useState } from "react";
import ReactMarkdown from "react-markdown";

function SummaryBlock({ summary }) {
  const [read, setRead] = useState(false);
  const mainUrl = process.env.GATSBY_API_URL;

  return (
    <section className="why-section container d-flex py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 order-1 order-lg-0">
            <h1 className="section-title">{summary.title}</h1>
            <ReactMarkdown className="section-content">
              {summary.description}
            </ReactMarkdown>

            {read === false && (
              <a onClick={() => setRead(true)} className="read-more">
                <br />
                Read more...
              </a>
            )}
            {read === true && (
              <div>
                <ReactMarkdown className="section-content signup-link">
                  {summary.ReadMore}
                </ReactMarkdown>
                <a onClick={() => setRead(false)} className="read-more">
                  <br />
                  Read less
                </a>
              </div>
            )}
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-start order-0 order-lg-1">
            {summary.image && (
              <img src={mainUrl + summary.image.url} className="w-100 my-5" />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default SummaryBlock;
