import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "gatsby";
import Layout from "../components/layout";
import smn_logo from "../images/SMN logo.svg";
import smn_logo_jpg from "../images/SMN_logo.jpg";
import smn_logo3 from "../images/smn_logo3.png";
import smn_logo4 from "../images/smn_logo4.png";
import smn_full_logo from "../images/smn.jpg";
import hero_gif from "../images/w-bg.gif";
import fsiAwardlogo from "../images/FSI-Award.jpg";
import charityGovernenceAward from "../images/Charity-Governance-Award.jpg";
import charityTimesAward from "../images/Charity-Times-Award.jpg";
import why_image from "../images/why.jpg";
import what_image from "../images/what.jpg";
import BOAlogo from "../images/BankofAmericalogo.png";
import InterestForm from "../components/form";
import bottomImage from "../images/bottom.jpg";
import logo1 from "../images/LaunchPartners/22.svg";
import logo2 from "../images/LaunchPartners/2.png";
import logo3 from "../images/LaunchPartners/3.png";
import logo4 from "../images/LaunchPartners/4.png";
import logo5 from "../images/LaunchPartners/5.png";
import logo6 from "../images/LaunchPartners/6.png";
import logo7 from "../images/LaunchPartners/7.png";
import logo8 from "../images/LaunchPartners/8.png";
import logo9 from "../images/LaunchPartners/9.png";
import logo10 from "../images/LaunchPartners/10.png";
import logo11 from "../images/LaunchPartners/11.png";
import logo12 from "../images/LaunchPartners/12.png";
import logo13 from "../images/LaunchPartners/13.png";
import logo14 from "../images/LaunchPartners/14.png";
import logo15 from "../images/LaunchPartners/15.png";
import logo16 from "../images/LaunchPartners/16.png";
import logo17 from "../images/LaunchPartners/17.png";
import logo18 from "../images/LaunchPartners/18.png";
import logo19 from "../images/LaunchPartners/19.png";
import logo20 from "../images/LaunchPartners/20.png";
import logo21 from "../images/LaunchPartners/21.png";
import logo22 from "../images/LaunchPartners/23.svg";
import aologo from "../images/allenovery.png";
import { Helmet } from "react-helmet";
import MyForm from "../components/contactForm";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import ReactMarkdown from "react-markdown";
import SummaryBlock from "../components/SummaryBlock";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Loader";
import CivicIntegration from "../components/Cookies/CookiesPage";
import GoogleConsentModeScript from "../components/Cookies/GoogleConsentMode";
import ReactGA from "react-ga";
import styled from "styled-components";

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <IndexPage />
    </QueryClientProvider>
  );
}

const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/smn-landingpage`);
};

const IndexPage = () => {
  const [newData, setNewData] = useState(null);
  const { data: pageData } = useQuery("smn", fetchData);
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
  }, [pageData]);
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <Layout>
      <Helmet>
        <title>Social Mobility Network by upReach</title>
        <meta
          name="description"
          content="The network for those without a network."
        />
        <meta
          property="og:title"
          content="Social Mobility Network by upReach"
        />
        <meta
          property="og:description"
          content="The network for those without a network."
        />
        <meta property="og:image" content={smn_logo4} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@up_Reach" />
        <meta
          name="twitter:title"
          content="Social Mobility Network by upReach"
        />
        <meta
          name="twitter:description"
          content="The network for those without a network."
        />
        <meta property="twitter:image" content={smn_logo4} />
        <meta name="twitter:image:width" content="280" />
        <meta name="twitter:image:height" content="480" />
      </Helmet>
      {newData === null && <Loader />}
      {newData && newData.showHeroImage && newData.SmnHeroImage && (
        <section className="hero-banner container d-flex flex-column justify-content-center">
          <div className="row mx-0">
            <div className="col-12 col-lg-5 d-flex flex-column justify-content-center">
              {newData.SmnHeroImage.heroLogo && (
                <img
                  src={mainUrl + newData.SmnHeroImage.heroLogo.url}
                  className="header-logo d-none d-lg-block"
                />
              )}

              <h1 className="description my-5 mx-3 text-center text-lg-left">
                {newData.SmnHeroImage.title}
              </h1>
            </div>
            <div className="col-12 col-lg-7">
              <img src={hero_gif} className="w-100 img-fluid" />
            </div>
          </div>
        </section>
      )}
      {newData && newData.showSmnWebinar && newData.SmnWebinar && (
        <section className="launch-section container d-flex flex-column">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8">
                <h1 className="section-title">{newData.SmnWebinar.title}</h1>
                <p className="my-3 section-content">
                  {newData.SmnWebinar.description}
                </p>
                <Link
                  to={newData.SmnWebinar.link}
                  target="_blank"
                  className="register-link"
                >
                  {newData.SmnWebinar.linkText}
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}
      {newData && newData.ShowSmnApplyNow && newData.SmnApplyNow && (
        <section className="launch-section container d-flex flex-column">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 id="applynow" className="section-title">{newData.SmnApplyNow.Title}</h1>
                <ReactMarkdown className="my-3 section-content">
                  {newData.SmnApplyNow.Description}
                </ReactMarkdown>
                <button className="btn-color m-0 my-4 btn-lg btn-block" onClick={() =>  window.location.href = '/apply-now'}>
                  <span className="text-white">{newData.SmnApplyNow.LinkText}</span>
                </button>
                <ReactMarkdown className="section-content">
                  {newData.SmnApplyNow.Description2}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </section>
      )}
      {newData && newData.showSmnAbout && newData.SmnAbout && (
        <section className="about-section container d-flex py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8">
                <h1 id="about" className="section-title">
                  {newData.SmnAbout.title}
                </h1>
                <ReactMarkdown className="my-3 section-content">
                  {newData.SmnAbout.description}
                </ReactMarkdown>
              </div>
              <div className="login-options">
                {newData.SmnAbout.logos.length > 0 &&
                  newData.SmnAbout.logos.map(
                    i =>
                      i.image && (
                        <img
                          src={mainUrl + i.image.url}
                          className="col-lg-4 col-8 col-md-6 about-images"
                        />
                      )
                  )}
              </div>
            </div>
          </div>
        </section>
      )}

      {newData && newData.showWhySummary && (
        <SummaryBlock summary={newData.whySummary} />
      )}
      {newData && newData.showWhatSummary && (
        <SummaryBlock summary={newData.whatSummary} />
      )}

      <section className="partner-section container d-flex py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {newData && newData.showPartnerDesc && (
                <div>
                  <h1 id="partners" className="section-title">
                    {newData.PartnerTitle}
                  </h1>
                  <div className="col-lg-9 px-0">
                    <ReactMarkdown className="section-content text-underline">
                      {newData.PartnerDesc}
                    </ReactMarkdown>
                  </div>
                </div>
              )}
              {newData && newData.showPartnerLogos && newData.PartnerLogos && (
                <div className="mt-5 align-center">
                  <h2 className="partner-heading">
                    {newData.PartnerLogos.title}
                  </h2>
                  <div className="row">
                    {newData.PartnerLogos.logos.length > 0 &&
                      newData.PartnerLogos.logos.map(i => (
                        <div className="col-12 col-md-6 col-lg-6 partner-logo-container">
                          {i.image && (
                            <img
                              className="partner-logo"
                              src={mainUrl + i.image.url}
                            />
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          {newData && newData.showLaunchPartner && newData.LaunchPartnerLogos && (
            <div>
              <div className="row mt-2">
                <div className="col d-flex justify-content-center">
                  <h2 className="partner-heading">
                    {newData.LaunchPartnerLogos.title}
                  </h2>
                </div>
              </div>
              <div className="row launch-partner-logo d-flex justify-content-center">
                {newData.LaunchPartnerLogos.logos.length > 0 &&
                  newData.LaunchPartnerLogos.logos.map(
                    i =>
                      i.image && (
                        <img
                          className="col-lg-2 col-md-3 col-4 logo-images"
                          src={mainUrl + i.image.url}
                        />
                      )
                  )}
              </div>
            </div>
          )}
        </div>
      </section>
      {newData && newData.showSmnLogin && newData.SmnLogin && (
        <section className="login-section container d-flex py-5">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="section-title" id="login">
                  {newData.SmnLogin.title}
                </h1>
                <p className="section-content">
                  {newData.SmnLogin.description}
                </p>
              </div>
            </div>
            <div className="row login-section-link mt-3">
              {newData.SmnLogin.SmnLink.length > 0 &&
                newData.SmnLogin.SmnLink.map(doc => (
                  <Link
                    className="col-12 col-md-6 col-lg-3 login-type"
                    to={doc.link !== undefined || null ? doc.link : ""}
                  >
                    <ReactMarkdown
                      className={`d-flex flex-direction-column align-items-center ${
                        doc.link !== null || undefined
                          ? "loginText1"
                          : "loginText2"
                      }`}
                    >
                      {doc.linkText}
                    </ReactMarkdown>
                  </Link>
                ))}
            </div>
          </div>
        </section>
      )}
      {newData && newData.showSmnDonate && newData.SmnDonate && (
        <section className="donate-section container d-flex py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-9">
                <h1 className="section-title" id="donate">
                  {newData.SmnDonate.title}
                </h1>
                <ReactMarkdown className="section-content text-underline">
                  {newData.SmnDonate.description}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </section>
      )}
      {newData && newData.showSmnContact && newData.SmnContact && (
        <section className="contact-section container d-flex py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-9">
                <h1 className="section-title" id="contact">
                  {newData.SmnContact.title}
                </h1>
                <ReactMarkdown className="section-content text-underline my-0">
                  {newData.SmnContact.description}
                </ReactMarkdown>
                <p className="form-required">*Required</p>
                {/* <InterestForm /> */}
                <MyForm />
              </div>
            </div>
          </div>
        </section>
      )}

      {/* <section className="solution-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 order-1 order-lg-0">
                        <h2>THE SOLUTION</h2>
                        <p>The idea of the Social Mobility Network came about in direct response to seeking a solution that addresses this problem. upReach have developed a platform which we want to position as: 'The network for those without a network'. A platform which gives direct access to professionals who work in a broad range of industries to allow any student using the platform an opportunity to speak to a person who works within the sector they are interested in. upReach is uniquely placed to create such a platform for two reasons: </p>
                        <p>1. Our existing work sees us boost social mobility by supporting students from less-advantaged backgrounds to secure top graduate jobs. We currently work with 1800 students and see the Social Mobility Network as a natural extension that complements our highly-personalised programme of support.</p>
                        <p>2. Our track record: Our students graduate from university with a median salary of £28,000 and 93% of our students who graduated in 2018 had secured an offer for and/or were in professional employment or further study 15 months after graduation. As a result of this success, we have since been awarded the Small Charity of the Year by the Charity Times Awards (2019) and the “Embracing Digital” 2020 Charity Governance Awards.</p>
                        <div>
                            <img src={fsi} className="mx-3" />
                            <img src={charity_year_img} className="mx-3"/>
                            <a href="#" className="d-flex my-5 register-button align-items-center justify-content-center mw-100 mx-auto mx-lg-0">REGISTER YOUR INTEREST</a>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 d-flex align-items-center order-0 order-lg-1">
                        <img src={laptop_mock_1} className="w-100 my-5"/>
                    </div>
                </div>
            </div>
        </section> */}
      {newData && newData.showBottomImage && newData.SmnBottomImage && (
        <section className="py-0">
          <img
            src={mainUrl + newData.SmnBottomImage.url}
            className="w-100 img-fluid px-0"
          />
        </section>
      )}
      <CivicIntegration></CivicIntegration>
      <GoogleConsentModeScript></GoogleConsentModeScript>
    </Layout>
  );
};
