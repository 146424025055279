import React,{useState} from 'react';
import { Button, Form } from "react-bootstrap"
import styled from "styled-components"

const StyledWrapper = styled.div``

const InterestForm = () => {
    const [name,setName] = useState()
    const [organization,setOrganization] = useState()
    const [email,setEmail] = useState()
    const [request,setRequest] = useState()

    const onSubmit = () => {
        console.log(name);
        console.log(organization);
        console.log(email);
        console.log(request);
    };

    return (
        <StyledWrapper>
            <Form onSubmit={onSubmit}>
                <h2 className="form-label">Name<span className="astrix-color">*</span></h2>
                <Form.Control onChange={(e)=> setName(e.target.value)} type="text" size="lg" name="Name" placeholder="Full Name" />
                <h2 className="form-label">Organisation/Role<span className="astrix-color">*</span></h2>
                <Form.Control type="text" onChange={(e)=>setOrganization(e.target.value)} size="lg" name="Organisation" placeholder="Title" />
                <Form.Group className="margin-bot-zero" controlId="formBasicEmail">
                    <h2 className="form-label">Email address<span className="astrix-color">*</span></h2>
                    <Form.Control type="email" onChange={(e) => setEmail(e.target.value)} name="EmailAddress" size="lg" placeholder="you@domain.com" />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <h2 className="form-label">Details of Request<span className="astrix-color">*</span></h2>
                    <Form.Control as="textarea" onChange={(e)=> setRequest(e.target.value)} size="lg" rows={4} name="Details" placeholder="Max. 500 characters" />
                </Form.Group>
                <Button className="btn-color" type="submit" block>
                    <p className="register-btn" onClick={onSubmit} >SUBMIT</p>
                </Button>
            </Form>
        </StyledWrapper>
    );
}

export default InterestForm;